import { createApp } from 'vue'
import AsyncComputed from "vue-async-computed"
import App from './App.vue'
import router from './router'
import store from './store'
import vue3GoogleLogin from 'vue3-google-login'
import '@/assets/styles/app.scss'
import * as bootstrap from 'bootstrap'
import "bootstrap-icons/font/bootstrap-icons.css"


const app = createApp(App)

app.use(store)
app.use(router)
app.use(vue3GoogleLogin, {
    clientId: '623336173285-33s0e07i4o9qo3g5i2fhkml54eve1csj.apps.googleusercontent.com'
})
app.use(AsyncComputed)

app.mount('#app')
