<template>
    <div class="d-flex flex-column" style="height: 100vh; max-height: 100vh; overflow-y: auto; overflow-x: hidden;">
        <navbar></navbar>
        <div class="d-flex flex-row flex-nowrap flex-grow-1">
            <sidebar></sidebar>
            <div class="container-fluid px-md-4 py-md-3">
                <div class="row">
                    <router-view />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'

export default {
    components: {
        Sidebar,
        Navbar
    },
}
</script>

<style lang="scss" scoped>

</style>