import { createRouter, createWebHistory } from 'vue-router'
import Page from '@/views/Page.vue'
import NotFound from '@/views/NotFound.vue'


const routes = [
  {
    path: '/',
    name: 'page',
    component: Page,
    children: [
      {
        path: '/backend',
        name: 'Backend',
        component: () => import('../views/Backend.vue'),
        auth: true
      },
      {
        path: '/downloads',
        name: 'Downloads',
        component: () => import('../views/Downloads.vue'),
        auth: true
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    auth: false
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound,
    auth: false
  },
  {
    path: '/:catchAll(.*)',
    redirect: '404',
  }
]

const router = createRouter({
  linkExactActiveClass: 'active',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(function (to, from, next) {

  // Faking a login screen here
  let user = JSON.parse(window.localStorage.getItem('user')) || { disabled: true }

  if ((to.path !== '/login' && to.path !== 'login') && user.disabled) {
    next({ path: '/login' })
  } else if ((to.path === '/login' || to.path === 'login') && !user.disabled) {
    next({ path: '/' })
  } else {
    next()
  }
})

export default router
